import React from 'react';

class About extends React.Component {
    render() {
        return (
            <div className="container about-app">
                <div className="section-heading inverse">
                    <h1>About</h1>
                    <div className="spacer"/>
                    <h2 className="gradient-header-low">Music Downloader & Player: 300 000 000+ online songs</h2>
                    <div className="spacer"/>
                    <div className="inline-links">
                        <a href="https://www.atplayer.com/blog.html" target="_blank" rel="noopener noreferrer"
                           className="btn-link label-primary inverse">
                            Blog
                        </a>
                        <span className="separator">|</span>
                        <a href="https://www.atplayer.com/faq.html" target="_blank" rel="noopener noreferrer"
                           className="btn-link label-primary inverse">
                            FAQ
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 col-sm-3 col-xs-6 fa-inverse">
                        <div className="about-item">
                            <i className="fa fa-info-circle fa-2x"/>
                            <h3 className="fa-inverse">User data</h3>
                            <p>Please read our <a href="https://www.atplayer.com/terms-of-use.html"
                                                  className="btn-outline-warning">Terms of Use</a> and <a href="https://www.atplayer.com/privacy-policy.html" className="btn-outline-warning">Privacy Policy</a> to understand about how we use, collect, disclose, and safeguard your information when you visit our mobile applications or website. Please read these documents carefully.
                                IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE PLATFORM
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-6 fa-inverse">
                        <div className="about-item  ">
                            <i className="fa fa-play-circle fa-2x"/>
                            <h3 className="fa-inverse">Import Playlists</h3>
                            <p>The app can import user's YouTube playlists for streaming. The app is compliant with all the YouTube API Terms and does not play videos in the background and it does not dowload YouTube videos. Please see below about how app enhances user functionality.</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-6 fa-inverse">
                        <div className="about-item  ">
                            <i className="fa fa-music fa-2x"/>
                            <h3 className="fa-inverse">Play YouTube Music</h3>
                            <p>300 000 000 songs and tons of movies. Import YouTube playlists. Listen to hundreds of millions of free songs! Any tracks, playlists, artists for every mood or activity. YouTube music can be streamed but NOT downloaded. This app is NOT a YouTube music downloader and does not play YouTube in the background or when the screen is turned off.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-6 fa-inverse">
                        <div className="about-item">
                            <i className="fa fa-download fa-2x"/>
                            <h3 className="fa-inverse">Download Music</h3>
                            <p>AT Player: best free music player and free music downloader. Offline MP3, FM Radio, and
                                YouTube. Hundreds of millions of downloads! Download over 7 millions songs (non YouTube) with super fast MP3
                                downloader engine.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 col-sm-3 col-xs-6 fa-inverse">
                        <div className="about-item  ">
                            <i className="fa fa-music fa-2x"/>
                            <h3 className="fa-inverse">Offline Music</h3>
                            <p>Music downloader takes download enabled music (not YouTube) offline to play whenever you want with
                                equalizer, variable speed, bass boost. Listen to offline music, FM Radio
                                even when the screen is off. Note: the app is not YouTube downloader.</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-6 fa-inverse">
                        <div className="about-item">
                            <i className="fa fa-globe fa-2x"/>
                            <h3 className="fa-inverse">Radio</h3>
                            <p>Listen to online music from many radio stations around the world. More than 90000 radio stations. Listen to FM Radio even when your screen is off.
                                Search now playing artists and songs.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-6 fa-inverse">
                        <div className="about-item  ">
                            <i className="fa fa-microphone fa-2x"/>
                            <h3 className="fa-inverse">Music Recognition</h3>
                            <p>Find any song playing nearby using a powerful music identification engine. Create playlists of similar music on the fly.</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-6 fa-inverse">
                        <div className="about-item  ">
                            <i className="fa fa-user fa-2x"/>
                            <h3 className="fa-inverse">Any song Any genre</h3>
                            <p>Search any music from songs library. Tons of predefined genres: Popular Music, Hip Hop, Rock, Classical, R&B, Jazz, Disco, Country, etc.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About