import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faYoutube,
    faFacebook,
    faInstagram,
    faXTwitter,
    faTelegram,
} from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

class Support extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="section-heading inverse">
                    <h1>Contact us</h1>
                    <div className="spacer"/>
                    <h2 className="gradient-header">Contact AT Player team to get support. We ❤️ to hear your feedback!</h2>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="platforms inverse">
                            <a href="https://t.me/atplayerchat" target="_blank"
                               className="btn btn-primary inverse custom-flex-btn">
                                <div className="icon-wrapper">
                                    <FontAwesomeIcon icon={faTelegram} className="icon-telegram" />
                                </div>
                                <div className="text-wrapper">
                                    <span>Telegram chat</span>
                                    <div>AT Player</div>
                                </div>
                            </a>
                            <a href="https://m.me/atplayer" target="_blank"
                               className="btn btn-primary inverse custom-flex-btn">
                                <div className="icon-wrapper">
                                    <FontAwesomeIcon icon={faFacebook} className="icon-facebook" />
                                </div>
                                <div className="text-wrapper">
                                    <span>Facebook chat</span>
                                    <div>AT Player</div>
                                </div>
                            </a>
                            <a href="https://twitter.com/messages/83880449-1012346897665744896" target="_blank"
                               className="btn btn-primary inverse custom-flex-btn">
                                <div className="icon-wrapper">
                                    <FontAwesomeIcon icon={faXTwitter} className="icon-xtwitter" />
                                </div>
                                <div className="text-wrapper">
                                    <span>Twitter chat</span>
                                    <div>AT Player</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="container">
                        <div className="section-heading inverse">
                            <a href="https://www.atplayer.com/blog.html" target="_blank">
                                <p className="btn-link label-primary inverse">Blog</p>
                            </a>
                            <a href="https://atplayer.com/terms-of-use.html#dmca-copyright-policy">
                                <p className="btn-link label-primary inverse">Copyright Information</p>
                            </a>
                            <a href="https://www.atplayer.com/privacy-policy.html" target="_blank">
                                <p className="btn-link label-primary inverse">Privacy Policy</p>
                            </a>
                            <a href="https://www.atplayer.com/terms-of-use.html" target="_blank">
                                <p className="btn-link label-primary inverse">Terms of Use</p>
                            </a>
                            <a href="https://www.atplayer.com/copyright_complaint_form" target="_blank">
                                <p className="btn-link label-primary inverse">DMCA Notice Form</p>
                            </a>
                            <a href="https://www.atplayer.com/faqs.html" target="_blank">
                                <p className="btn-link label-primary inverse">Frequently Asked Questions</p>
                            </a>
                        </div>
                        <div className="social">
                            <a href="https://www.youtube.com/c/atplayer" target="_blank">
                                <FontAwesomeIcon icon={faYoutube} size="lg" className="fa" />
                            </a>
                            <a href="https://www.facebook.com/atplayer" target="_blank">
                                <FontAwesomeIcon icon={faFacebook} size="lg" className="fa" />
                            </a>
                            <a href="https://www.instagram.com/atmusicplayer" target="_blank">
                                <FontAwesomeIcon icon={faInstagram} size="lg" className="fa" />
                            </a>
                            <a href="https://twitter.com/atmusicplayer" target="_blank">
                                <FontAwesomeIcon icon={faXTwitter} size="lg" className="fa" />
                            </a>
                            <a href="https://t.me/atplayerchat" target="_blank">
                                <FontAwesomeIcon icon={faTelegram} size="lg" className="fa" />
                            </a>
                        </div>
                        <div className="rights">
                            <p>Copyright © 2024 Super Free Music Player Apps</p>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Support